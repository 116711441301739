import { ArrowLeft, ArrowRight } from "@abnormalComponents/Icons";
import { Box, Button, Flex, Heading, Tab, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";
import { PlasmicCanvasContext } from "@plasmicapp/loader-nextjs";
import { useRouter } from "next/router";
import { ReactNode, useContext, useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { clsx } from 'clsx';
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  className?: string;
  children?: ReactNode | any;
  previewTab?: number;
  tabId?: string;
  tabStyle?: string;
  tabPlacement?: 'Left' | 'Right';
  tabWidth?: string;
  tabsSpacing?: string;
  tabsHeading?: string;
  borderRadius?: string;
  centerTabs?: boolean;
  separateTabs?: boolean;
  activeTabTextColor?: string;
  inactiveTabTextColor?: string;
  activeTabFontWeight?: string;
  inactiveTabFontWeight?: string;
  activeTabColor?: string;
  inactiveTabColor?: string;
  backgroundColor?: string;
  toggleEdgeBorder?: boolean;
  borderStyle?: string;
  edgeBorderWidth?: string;
  edgeBorderActiveColor?: string;
  edgeBorderInactiveColor?: string;
  dividerColor?: string;
  hideButtons?: boolean;
  animateButtons?: boolean;
  overlayButtons?: boolean;
  buttonBackgroundColor?: string;
  buttonXOffset?: string;
  buttonOffset?: string;
  noYPadding?: boolean;
}

const TabsBlock = ({
  className,
  children,
  previewTab,
  tabId,
  tabStyle,
  tabPlacement = "Left",
  tabWidth = "200px",
  tabsSpacing = "12px",
  centerTabs = false,
  separateTabs = true,
  borderRadius = "2px",
  tabsHeading,
  activeTabTextColor,
  inactiveTabTextColor,
  activeTabFontWeight = "500",
  inactiveTabFontWeight,
  activeTabColor,
  inactiveTabColor,
  backgroundColor = "transparent",
  toggleEdgeBorder = true,
  borderStyle = "active",
  edgeBorderWidth = "1px",
  edgeBorderActiveColor = "violet-02",
  edgeBorderInactiveColor = "violet-02",
  dividerColor = "violet-02",
  hideButtons = false,
  animateButtons = false,
  overlayButtons = false,
  buttonBackgroundColor = "#FFFFFF",
  buttonXOffset,
  buttonOffset,
  noYPadding = false,
}: IProps) => {
  if (typeof children.length === "undefined") return children;

  const [initialIndex, setInitialIndex] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const [id, setId] = useState(() => { return !!tabId ? tabId : uuidv4(); });

  const isVertical = (tabStyle === "vertical");

  let router = { query: {}, isReady: false };
  try {
    router = useRouter();
  } catch {
  } [router];
  const { query, isReady } = router;

  useEffect(() => {
    //set default index, and active tab from query param
    if (isReady) {
      let index = 1;
      const tab = Number(query.tab);
      const length = children.length;
      if (tab >= 1 && tab <= length) {
        index = tab;
      }
      setInitialIndex(index);
      setActiveTab(index - 1);
    }
  }, [query]);

  const inEditor = useContext(PlasmicCanvasContext);

  useEffect(() => {
    // Programmatically control active tab when in studio editor
    if (inEditor) {
      const previewMax = children.length;
      let preview = Math.abs(previewTab);
      if (preview > previewMax) {
        preview = preview % previewMax;
      }
      setActiveTab(preview);
    }
  }, [previewTab]);

  if (!initialIndex) return null;

  const TabsSide = () => {
    return (
      <Box width="100%" maxW={tabWidth}
        borderRight={tabPlacement === 'Left' ? "1px solid" : null}
        borderRightColor={tabPlacement === 'Left' ? dividerColor : null}
        borderLeft={tabPlacement === 'Right' ? "1px solid" : null}
        borderLeftColor={tabPlacement === 'Right' ? dividerColor : null}
        paddingRight={tabPlacement === 'Left' ? "5" : null}
        paddingLeft={tabPlacement === 'Right' ? "5" : null}
        marginRight={tabPlacement === 'Left' ? "5" : null}
        marginLeft={tabPlacement === 'Right' ? "5" : null}
      >
        {tabsHeading && (
          <Heading as="h2" size="2xl" pb="2">{tabsHeading}</Heading>
        )}
        {children && (
          <VStack spacing={tabsSpacing} align="stretch">
            {children.map((tab, index) => (
              <Tab
                fontWeight={activeTab === index ? activeTabFontWeight : inactiveTabFontWeight}
                color={activeTab === index ? activeTabTextColor : inactiveTabTextColor}
                backgroundColor={activeTab === index ? activeTabColor : inactiveTabColor}
                key={index}
                _selected={activeTab === index && {
                  backgroundColor: activeTabColor,
                  color: activeTabTextColor,
                  fontWeight: activeTabFontWeight,
                }}
                onClick={() => setActiveTab(index)}
                borderLeft={toggleEdgeBorder ? (activeTab === index ? `${edgeBorderWidth} solid ${edgeBorderActiveColor}` : `${edgeBorderWidth} solid ${edgeBorderInactiveColor}`) : null}
              >
                {tab?.props?.heading}
              </Tab>
            ))}
          </VStack>
        )}
      </Box>
    );
  }

  const LeftArrow = ({scrollMethod = "group"}) => {
    const { isLastItemVisible, isFirstItemVisible, getItemByIndex, scrollPrev, scrollToItem } =
      useContext(VisibilityContext);
    const buttonProps = {
      // as: motion.div,
      // layout: true,
      cursor: "pointer",
      variant: "unstyled",
      width: "12",
      height: "12",
      px: 2,
      mt: !!buttonOffset ? buttonOffset: 0,
      rounded: "full",
      alignItems: "center",
      backgroundColor: buttonBackgroundColor,
      display: scrollMethod === "group" && isFirstItemVisible && isLastItemVisible ? "none" : "flex",
      left: overlayButtons ? (!!buttonXOffset ? buttonXOffset : "1.875rem") : 0,
      zIndex: 10,
      sx: {
        pointerEvents: activeTab === 0 ? "none" : null,
        position: overlayButtons ? "absolute" : "static",
      },
      _hover: {
        transform: animateButtons && "translateX(-0.25rem)"
      }
    }

    return (
      <Box display={hideButtons ? "none" : "block"}>
        <Button
          {...buttonProps}
          opacity={(scrollMethod !== "single" && isFirstItemVisible) || (activeTab === 0)
            ? overlayButtons ? 0.6 : 0.2
            : 1}
          onClick={() => {
            if (scrollMethod !== "single") {
              scrollPrev();
              return;
            }
            const prevTab = getItemByIndex(activeTab - 1);
            if (!prevTab) return;
            //!prevTab?.visible && scrollToItem(prevTab);
            setActiveTab(+prevTab.index)
            scrollToItem(prevTab);
          }}
        >
          <ArrowLeft />
        </Button>
        {overlayButtons && (<Box
          {...buttonProps}
          backgroundColor={inactiveTabColor}
          zIndex="5"
        ></Box>)}
      </Box>
    );
  }

  const RightArrow = ({scrollMethod = "group"}) => {
    const { isLastItemVisible, isFirstItemVisible, getItemByIndex, scrollNext, scrollToItem } =
      useContext(VisibilityContext);
    const buttonProps = {
      // as: motion.div,
      // layout: true,
      cursor: "pointer",
      variant: "unstyled",
      width: "12",
      height: "12",
      px: 2,
      mt: !!buttonOffset ? buttonOffset: 0,
      rounded: "full",
      alignItems: "center",
      backgroundColor: buttonBackgroundColor,
      display: scrollMethod === "group" && isFirstItemVisible && isLastItemVisible ? "none" : "flex",
      right: overlayButtons ? (!!buttonXOffset ? buttonXOffset : "1.875rem") : 0,
      zIndex: 10,
      sx: {
        pointerEvents: activeTab === children.length - 1 ? "none" : null,
        position: overlayButtons ? "absolute" : "static",
      },
      _hover: {
        transform: animateButtons && "translateX(0.25rem)"
      }
    }

    return (
      <Box display={hideButtons ? "none" : "block"}>
        <Button
          {...buttonProps}
          opacity={(scrollMethod !== "single" && isFirstItemVisible) || (activeTab === children.length - 1)
            ? overlayButtons ? 0.6 : 0.2
            : 1}
          onClick={() => {
            if (scrollMethod !== "single") {
              scrollNext();
              return;
            }
            const nextTab = getItemByIndex(activeTab + 1);
            if (!nextTab) return;
            // !nextTab?.visible && scrollToItem(nextTab);
            setActiveTab(+nextTab.index)
            scrollToItem(nextTab);
          }}
        >
          <ArrowRight />
        </Button>
        {overlayButtons && (<Box
          {...buttonProps}
          backgroundColor={inactiveTabColor}
          zIndex="5"
        ></Box>)}
      </Box>
    );
  }

  return (
    <Tabs
      index={activeTab}
      variant={tabStyle}
      backgroundColor={isVertical ? backgroundColor : null}
      defaultIndex={initialIndex - 1}
      key={initialIndex}
    >
      <Box maxWidth="1240px" mx="auto" width="full" display={isVertical ? 'none' : 'block'} className={isVertical ? '' : className}>
        <ScrollMenu
          LeftArrow={() => <LeftArrow scrollMethod="single" />}
          RightArrow={() => <RightArrow scrollMethod="single" />}
          scrollContainerClassName={clsx("flex justify-start overflow-hidden", { "md:justify-center": centerTabs })}
          separatorClassName={separateTabs ? "pr-2" : ""}
          onUpdate={(visibility) => {
            // If the active tab is not visible, scroll to it
            //const current = visibility.getItemByIndex(activeTab);
            //!current?.visible && visibility.scrollToItem(current);
          }}>
          {children &&
            children.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  id={`${id}-${index}`}
                  // as={motion.div}
                  // layout
                  height={"100%"}
                  margin={0}
                  color={
                    activeTab === index
                      ? activeTabTextColor
                      : inactiveTabTextColor
                  }
                  backgroundColor={
                    activeTab === index ? activeTabColor : inactiveTabColor
                  }
                  borderTop={toggleEdgeBorder ?
                    (
                      activeTab === index || borderStyle === "progress" && activeTab > index
                        ? `${edgeBorderWidth} solid ${edgeBorderActiveColor}`
                        : `${edgeBorderWidth} solid ${edgeBorderInactiveColor}`
                    )
                    : null
                  }
                  borderRadius={`${borderRadius} ${borderRadius} 0 0`}
                  _selected={
                    activeTab === index && {
                      backgroundColor: activeTabColor,
                      color: activeTabTextColor,
                    }
                  }
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {tab?.props?.heading}
                </Tab>
              );
            })
          }
        </ScrollMenu>
      </Box>

      <Box mx="auto" width="full" display={isVertical ? 'flex' : 'block'} className={isVertical ? className : ''} color="inherit">

        {isVertical && tabPlacement === 'Left' && (
          <TabsSide />
        )}

        <TabPanels>
          {children &&
            children.map((tab, index) => {
              return (
                <TabPanel key={index} backgroundColor={backgroundColor} py={noYPadding?"0":"10"}>
                  <Flex mx="auto" flexDirection="column">
                    <Box color="inherit">{tab?.props?.content}</Box>
                  </Flex>
                </TabPanel>
              );
            })
          }
        </TabPanels>

        {isVertical && tabPlacement === 'Right' && (
          <TabsSide />
        )}

      </Box>
    </Tabs>
  );
};

export default TabsBlock;
