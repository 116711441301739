import clsx from "clsx";

import VideoEmbed from "@components/VideoEmbed";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";

const VideoSlideDown = ({
  videoUrl = null,
  isVideoOpen = false,
  onClose = () => null
}): JSX.Element => {

  return (
    <Transition
      as="div"
      show={isVideoOpen}
      enter="transition-[max-height] duration-700 ease-out"
      enterFrom="max-h-0"
      enterTo="max-h-250"
      leave="transition-[max-height] duration-700 ease-in"
      leaveFrom="max-h-250"
      leaveTo="max-h-0"
      className="overflow-hidden"
    >
        <div className={clsx(
            "SideBySide__slideDownVideo bg-black-01"
        )}>
            <div className={clsx(
            "SideBySide__slideDownVideo-container relative mx-auto max-w-screen-xl"
            )}>
                <div className={clsx(
                "SideBySide__slideDownVideo-wrapper aspect-w-16 aspect-h-9"
                )}>
                    
                        <div className="h-full w-full bg-white-01">
                            <VideoEmbed url={videoUrl} autoplay videoStyle="slideDown" onClose={onClose}/>
                        </div>
                </div>
            </div>
        </div>
    </Transition>
  );
};

export default VideoSlideDown;
