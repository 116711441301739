import { initPlasmicLoader } from "@plasmicapp/loader-nextjs";
import { Container } from "@plasmicComponents/Container";
import FormFields from "@plasmicComponents/FormFields";

import { Prose } from "@nikolovlazar/chakra-ui-prose";
import Accordion from "@plasmicComponents/Accordion";
import AccordionItem from "@plasmicComponents/AccordionItem";
import AudioPlayer from "@plasmicComponents/AudioPlayer";
import ClickEvent from "@plasmicComponents/ClickEvent";
import ControlledSliders from "@plasmicComponents/ControlledSliders";
import CountdownPlasmic from "@plasmicComponents/CountdownPlasmic";
import EmbeddedFormPlasmic from "@plasmicComponents/EmbeddedFormPlasmic";
import EmbeddedVideoPlasmic from "@plasmicComponents/EmbeddedVideoPlasmic";
import FloatingNav from "@plasmicComponents/FloatingNav";
import FloatingNavItem from "@plasmicComponents/FloatingNavItem";
import FramerWrapper from "@plasmicComponents/FramerWrapper";
import MasonryWrapper from "@plasmicComponents/Masonry";
import ModalVideoPlasmic from "@plasmicComponents/ModalVideoPlasmic";
import PagePropsProvider from "@plasmicComponents/PagePropsProvider";
import Pagination from "@plasmicComponents/Pagination";
import RelatedResourcesBlade from "@plasmicComponents/RelatedResourcesBlade";
import Slider from "@plasmicComponents/Slider";
import SliderItem from "@plasmicComponents/SliderItem";
import TabItem from "@plasmicComponents/TabItem";
import Tabs from "@plasmicComponents/Tabs";
import ColorPalette from "@plasmicPlugins/colorPalette";
import CustomDateProp from "@plasmicPlugins/customDateProp";
import FormField from "@plasmicPlugins/FormField";
import PageRoute from "@plasmicPlugins/pageRoute";
import PreviewControl from "@plasmicPlugins/previewControl";
import { registerChakraProvider } from "./lib/registerChakraProvider";

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PAGES_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_PAGES_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_LANDING_PAGES_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_LANDING_PAGES_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_LEGAL_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_LEGAL_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PARTNERS_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_PARTNERS_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PRODUCTS_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_PRODUCTS_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_SOLUTIONS_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_SOLUTIONS_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_WHY_ABNORMAL_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_WHY_ABNORMAL_PROJECT_TOKEN, // API token for that project
    },
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PROJECT_ID, // ID of a project you are using
      token: process.env.NEXT_PUBLIC_PLASMIC_PROJECT_TOKEN, // API token for that project
    },
  ],

  // By default Plasmic will use the last published version of your project.
  // For development, you can set preview to true, which will use the unpublished
  // project, allowing you to see your designs without publishing.  Please
  // only use this for development, as this is significantly slower.
  preview:
  process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
    process.env.VERCEL_ENV === "preview" ||
    process.env.NODE_ENV !== "production",
});

registerChakraProvider(PLASMIC, {
  displayName: "Chakra-UI ChakraProvider",
  importName: "ChakraProvider",
  importPath: "@chakra-ui/react",
  name: "chakra-ui-ChakraProvider",
  props: {
    theme: {
      type: "object",
    },
  },
});

PLASMIC.registerComponent(EmbeddedFormPlasmic, {
  name: "Form Component",
  defaultStyles: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  props: {
    children: "slot",
    successMessage: "slot",
    formId: {
      type: "string",
      readOnly: true,
      hidden: () => true,
    },
    marketoFormId: {
      type: "string",
      readOnly: true,
    },
    formName: {
      type: "string",
      readOnly: true,
      hidden: () => true,
    },
    showHidden: {
      type: "boolean",
      defaultValue: true,
      displayName: "Show Hidden Fields",
    },
    darkBackground: {
      type: "boolean",
      defaultValue: false,
      displayName: "On Dark Background",
      helpText: "Adjusts various field styles for dark background.",
    },
    inputColorScheme: {
      type: "choice",
      options: ["default", "gray"],
      defaultValue: "default",
      displayName: "Input Color Scheme",
      helpText: "Select the color scheme for the form fields.",
    },
  },
  actions: [
    {
      type: "custom-action",
      control: FormField,
    },
  ],
});

PLASMIC.registerComponent(FormFields, {
  name: "Form Fields",
  displayName: "Form Field",
  props: {
    fieldType: {
      type: "choice",
      options: [
        "text",
        "email",
        "telephone",
        "number",
        "date",
        "dateTime",
        "select",
        "checkbox",
        "radioButtons",
        "textArea",
        "hidden",
        "htmltext",
      ],
    },
    label: "string",
    placeholder: "string",
    layout: {
      type: "choice",
      options: [
        {
          value: "row",
          label: "Horizontal"
        },
        {
          value: "column",
          label: "Vertical"
        }
      ],
      defaultValue: "column",
      hidden: (props) => props.fieldType !== "radioButtons",
    },
    options: {
      type: "array",
      hidden: (props) => props.fieldType !== "select" && props.fieldType !== "radioButtons",
      itemType: {
        type: "object",
        fields: {
          isDefault: {
            type: "boolean",
            displayName: "Make this the default option?",
            hidden: (props) => props.fieldType === "radioButtons"
          },
          label: "string",
          value: "string",
        },
        nameFunc: (obj) => obj.label,
      },
    },
    defaultValue: {
      type: "string",
      hidden: (props) => props.fieldType === "select",
    },
    fieldName: {
      type: "string",
      readOnly: true,
    },
    required: "boolean",
    slimMode: {
      type: "boolean",
      displayName: "Slim Mode",
    },
    toggleVisibility: {
      type: "object",
      fields: {
        fieldName: {
          type: "string",
          displayName: "Target Field",
        },
        targetValue: {
          type: "string",
          displayName: "Target Value",
        },
      },
    },
  },
});


PLASMIC.registerComponent(Prose, {
  name: "Prose",
  props: {
    children: {
      type: "slot",
    },
  },
});

PLASMIC.registerComponent(AudioPlayer, { 
  name: "AudioPlayer",
  displayName: "Audio Player",
  props: {
    audioFileUrl: {
      type: "string",
      defaultValue: "https://file-examples.com/storage/fe0e5e78596682d89b36118/2017/11/file_example_MP3_700KB.mp3",
    },
  },
});

PLASMIC.registerComponent(FloatingNav, {
  name: "FloatingNav",
  displayName: "Floating Nav",
  defaultStyles: {
    position: "sticky",
  },
  props: {
    children: {
      type: "slot",
      allowedComponents: ["FloatingNavItem"],
      defaultValue: [
        {
          type: "component",
          name: "FloatingNavItem",
        },
        {
          type: "component",
          name: "FloatingNavItem",
        },
      ],
    },
  },
  actions: [
    {
      type: "button-action",
      label: "Add Nav Item",
      onClick: ({ studioOps }) => {
        studioOps.appendToSlot(
          {
            type: "component",
            name: "FloatingNavItem",
          },
          "children"
        );
      },
    },
  ],
});

PLASMIC.registerComponent(FloatingNavItem, {
  name: "FloatingNavItem",
  displayName: "Floating Nav Item",
  props: {
    text: {
      type: "string",
      defaultValue: "Item Text",
    },
    anchorName: "string",
  },
});

PLASMIC.registerComponent(FramerWrapper, {
  name: "FramerWrapper",
  displayName: "Framer Wrapper",
  props: {
    className: {
      type: "string",
      defaultValue: "",
    },
    children: {
      type: "slot",
      defaultValue: null,
    },
    animations: {
      // Treat this as a code-like object for advanced customization
      type: "object",
      defaultValue: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      },
    },
    disabled: {
      type: "boolean",
      defaultValue: false,
      displayName: "Disable Animations",
      description: "Disables all animations, useful for debugging or styling.",
    },
    layoutAnimations: {
      type: "boolean",
      defaultValue: false,
      displayName: "Enable Layout Animations",
      description: "Animates layout changes, such as position or opacity.",
    },
    triggerOnce: {
      type: "boolean",
      displayName: "Trigger Only Once",
      defaultValue: true,
    },
  },
  
});




PLASMIC.registerComponent(Container, {
  name: "Container",
  props: {
    children: "slot",
    anchorName: {
      type: "string",
      displayName: "Anchor Name",
    },
    backgroundImage: {
      type: "imageUrl",
      displayName: "BG Image",
    },
    backgroundColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "BG Color",
    },
    backgroundBlend: {
      type: "boolean",
      hidden: (props) => !props.backgroundImage && !props.backgroundColor,
      displayName: "BG Blend",
    },
    addHeaderPadding: {
      type: "boolean",
      displayName: "Nav Padding",
    },
    noXPadding: {
      type: "boolean",
      displayName: "No X Padding",
    },
    /*Built in Hex Picker + Style Tokens (Can"t disable hex picker)
    backgroundColor: {
      type: "color",
      format: "hex",
    },
    */
    maxWidth: {
      type: "choice",
      options: ["1200px", "1400px", "100%"],
      defaultValue: "1200px",
    },
  },
});

PLASMIC.registerComponent(EmbeddedVideoPlasmic, {
  name: "EmbeddedVideo",
  displayName: "Embedded Video",
  props: {
    src: {
      type: "string",
      displayName: "Video URL",
      defaultValue: "https://abnormalsecurity-1.wistia.com/medias/acf2c3p0cm",
    },
    autoplay: {
      type: "boolean",
      displayName: "Autoplay",
      defaultValue: false,
    },
    controls: {
      type: "boolean",
      displayName: "Show Controls",
      defaultValue: true,
    },
    iconColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Icon Color",
      defaultValue: "#6863F2",
    },
    playsInline: {
      type: "boolean",
      displayName: "Play Inline",
      description: "Usually on mobile, turning this on will force video to play where it resides on the page instead of opening in a fullscreen.",
      defaultValue: false,
    },
    loop: {
      type: "boolean",
      displayName: "Loop Video",
      description: "Turn this on to loop the media.",
      defaultValue: false,
    },
    playMuted: {
      type: "boolean",
      displayName: "Play Muted",
      description: "Start video muted. IMPORTANT: Autoplay requires video to start muted, autoplay on will override this setting.",
    },
  },
});

PLASMIC.registerComponent(ModalVideoPlasmic, {
  name: "ModalVideoPlasmic",
  displayName: "Modal Video",
  props: {
    autoplay: {
      type: "boolean",
      displayName: "Autoplay",
      description: "Turn this on to have the video autoplay when the page loads",
      defaultValue: false
    },
    controls: {
      type: "boolean",
      displayName: "Video Controls",
      description: "Turn on to enable video controls",
      defaultValue: false
    },
    centered: {
      type: "boolean",
      displayName: "Centered",
      description: "Vertically center the modal in the viewport",
      defaultValue: false
    },
    showPlayIcon: {
      type: "boolean",
      displayName: "Show Play Icon",
      description: "Turn this on to display the video play icon.",
      defaultValue: true
    },
    playIconWidth: {
      type: "string",
      displayName: "Play Icon Width",
      defaultValue: "101px",
      hidden: props => !props.showPlayIcon
    },
    videoUrl: {
      type: "string",
      displayName: "Video URL",
      description: "Enter the full URL to your video",
      defaultValue: "https://abnormalsecurity-1.wistia.com/medias/acf2c3p0cm"
    },
    videoCoverImage: {
      type: "imageUrl",
      displayName: "Video Thumbnail Image",
      description: "Upload a default thumbnail image for the video",
      defaultValue: ""
    },
    videoCoverAltDesc: {
      type: "string",
      displayName: "Video Alt Description",
      description: "Add an alt description for your video",
      defaultValue: ""
    },
    iconColor: {
      type: "string",
      displayName: "Play Icon Color",
      description: "Set the color of the play button / icon",
      defaultValue: "#6863f2"
    },
    iconColorHover: {
      type: "string",
      displayName: "Play Icon Hover Color",
      defaultValue: "#8985f2",
    },
    size: {
      type: "choice",
      options: [
        {
          value: "xs",
          label: "XSmall"
        },
        {
          value: "sm",
          label: "Small"
        },
        {
          value: "md",
          label: "Medium"
        },
        {
          value: "lg",
          label: "Large"
        },
        {
          value: "xl",
          label: "XLarge"
        },
        {
          value: "full",
          label: "Full"
        }
      ],
      displayName: "Modal Size",
      description: "Set size of modal",
      defaultValue: "xl"
    },
    returnFocusOnClose: {
      type: "boolean",
      displayName: "Return Focus on Close",
      description: "Return focus to the button that opened the modal on close",
      defaultValue: true
    },
  },
});

PLASMIC.registerComponent(Accordion, {
  name: "Accordion",
  props: {
    children: {
      type: "slot",
      allowedComponents: ["AccordionItem"],
      defaultValue: [
        {
          type: "component",
          name: "AccordionItem",
        },
        {
          type: "component",
          name: "AccordionItem",
        },
      ],
    },
    expandAll: {
      type: "boolean",
      defaultValue: true,
      displayName: "Expand All",
      description: "All accordion items will be expanded by default.",
    },
    allowMultiple: {
      type: "boolean",
      defaultValue: false,
      displayName: "Allow Multiple",
      description: "Multiple accordion items can be expanded at once."
    },
    allowToggle: {
      type: "boolean",
      defaultValue: true,
      displayName: "Allow Toggle",
      description: "Expanded accordion item(s) can be collapsed again."
    },
    reduceMotion: {
      type: "boolean",
      defaultValue: false,
      displayName: "Reduce Motion",
      description: "Disable height animation and transitions."
    },
    style: {
      type: "choice",
      options: [
        { value: "default", label: "Default (Arrow)" },
        { value: "border", label: "Border" },
        { value: "card", label: "Card" },
      ],
      defaultValue: "default",
      displayName: "Style",
      description: "Style of accordion items.",      
    },
    spacing: {
      type: "number",
      control: "slider",
      step: 4,
      min: 0,
      max: 80,
      defaultValue: 0,
      displayName: "Spacing",
      description: "Spacing after expanded accordion items",
    },
    onExpandedChange: {
      type: "eventHandler",
      argTypes: [{ name: "expandedItems", type: "array" }]
    }
  },
  states: {
    expandedItems: {
      type: "readonly",
      variableType: "array",
      onChangeProp: "onExpandedChange",
      initVal: [],
    },
  },
  actions: [
    {
      type: "button-action",
      label: "Add Accordion Item",
      onClick: ({ studioOps }) => {
        studioOps.appendToSlot(
          {
            type: "component",
            name: "AccordionItem",
          },
          "children"
        );
      },
    },
  ],
});

PLASMIC.registerComponent(AccordionItem, {
  name: "AccordionItem",
  displayName: "Accordion Item",
  parentComponentName: "Accordion",
  styleSections: false,
  props: {
    heading: {
      type: "slot",
      defaultValue: [
        {
          type: "text",
          tag: "h2",
          value: "Enter Accordion Heading...",
          styles: {
            fontSize: "24px",
            fontWeight: "normal",
            maxWidth: "100%",
          },
        },
      ],
    },
    content: {
      type: "slot",
      defaultValue: [
        {
          type: "text",
          value: "Enter Accordion Content...",
          styles: {
            maxWidth: "100%",
          },
        },
      ],
    },
    id: {
      type: "string",
      displayName: "ID",
      description: "Unique ID for the accordion item.",
    },
    expanded: {
      type: "boolean",
      displayName: "Start Expanded",
      description: "Accordion item will be expanded by default."
    }
  },
});

PLASMIC.registerComponent(Tabs, {
  name: "Tabs",
  props: {
    children: {
      type: "slot",
      allowedComponents: ["TabItem"],
      defaultValue: [
        {
          type: "component",
          name: "TabItem",
        },
        {
          type: "component",
          name: "TabItem",
        },
      ],
    },
    previewTab: {
      type: "custom",
      control: PreviewControl,
      displayName: "Preview Tab",
      defaultValue: 0,
    },
    // Basic Config
    tabId: {
      type: "string",
      displayName: "Tab ID",
      description: "Unique ID for the tab.",
    },
    tabStyle: {
      type: "choice",
      options: [
        {
          value: "horizontal",
          label: "Horizontal"
        },
        {
          value: "vertical",
          label: "Vertical"
        }
      ],
      defaultValue: "horizontal",
      displayName: "Tab Style"
    },
    tabPlacement: {
      type: "choice",
      options: [
        { value: "Left", label: "Left" },
        { value: "Right", label: "Right" },
      ],
      defaultValue: "Left",
      displayName: "Tab Placement",
      helpText: "Select the placement of the tabs. Only for vertical layout.",
      hidden: props => props.tabStyle === "horizontal"
    },
    tabWidth: {
      type: "string",
      defaultValue: "200px",
      displayName: "Tab Width",
      hidden: props => props.tabStyle === "horizontal"
    },
    tabsSpacing: {
      type: "string",
      displayName: "Tabs Spacing",
      defaultValue: "12px",
      helpText: "Displays only on vertical tab layouts.",
      hidden: props => props.tabStyle === "horizontal"
    },
    tabsHeading: {
      type: "string",
      displayName: "Tabs Heading",
      helpText: "Displays only on vertical tab layouts.",
      hidden: props => props.tabStyle === "horizontal"
    },
    borderRadius: {
      type: "string",
      defaultValue: "2px",
      displayName: "Tab Border Radius",
      description: "Only affects the top of the tab. Only for horizontal layouts.",
      hidden: props => props.tabStyle === "vertical"
    },
    centerTabs: {
      type: "boolean",
      defaultValue: false,
      displayName: "Center Tabs",
    },
    separateTabs: {
      type: "boolean",
      defaultValue: true,
      displayName: "Separate Tabs",
      description: "Separate tabs with space. Only for horizontal layouts.",
      hidden: props => props.tabStyle === "vertical"
    },
    // Colors and Text Styles
    activeTabTextColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Active Tab Text Color",
    },
    inactiveTabTextColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Inactive Tab Text Color",
    },
    activeTabFontWeight: { 
      type: "choice",
      options: [
        { value: "400", label: "Normal" },
        { value: "500", label: "Medium" }
      ],
      defaultValue: "400",
      displayName: "Active Tab Font Weight",
      hidden: props => props.tabStyle === "horizontal"
    },
    inactiveTabFontWeight: { 
      type: "choice",
      options: [
        { value: "400", label: "Normal" },
        { value: "500", label: "Medium" }
      ],
      defaultValue: "400",
      displayName: "Inactive Tab Font Weight",
    },
    activeTabColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Active Tab Color",
    },
    inactiveTabColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Inactive Tab Color",
    },
    backgroundColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Background Color",
    },
    // Borders
    toggleEdgeBorder: {
      type: "boolean",
      defaultValue: true,
      displayName: "Show Border?",
    },
    borderStyle: {
      type: "choice",
      options: [
        { value: "active", label: "Active Tab Style" },
        { value: "progress", label: "Progress Style" }
      ],
      defaultValue: "active",
      displayName: "Border Highlight Style",
      hidden: props => !props.toggleEdgeBorder || props.tabStyle === "vertical"
    },
    edgeBorderWidth: {
      type: "string",
      defaultValue: "1px",
      displayName: "Border Width",
      hidden: props => !props.toggleEdgeBorder,
    },
    edgeBorderActiveColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Active Tab Border Color",
      hidden: props => !props.toggleEdgeBorder
    },
    edgeBorderInactiveColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Inactive Tab Border Color",
      hidden: props => !props.toggleEdgeBorder
    },
    dividerColor: {
      type: "custom",
      control: ColorPalette,
      defaultValue: "violet-02",
      displayName: "Divider Color",
      hidden: props => props.tabStyle === "horizontal"
    },
    // Navigation
    animateButtons: {
      type: "boolean",
      defaultValue: false,
      displayName: "Animate Buttons",
      hidden: props => props.hideButtons || props.tabStyle === "vertical"
    },
    overlayButtons: {
      type: "boolean",
      defaultValue: false,
      displayName: "Overlay Buttons",
      hidden: props => props.hideButtons || props.tabStyle === "vertical"
    },
    buttonBackgroundColor: {
      type: "custom",
      control: ColorPalette,
      defaultValue: "#FFFFFF",
      displayName: "Button Background Color",
      hidden: props => props.hideButtons || props.tabStyle === "vertical"
    },
    buttonXOffset: {
      type: "string",
      defaultValue: "1.875rem",
      displayName: "Button Horizontal Offset",
      hidden: props => props.hideButtons || props.tabStyle === "vertical"
    },
    buttonOffset: {
      type: "string",
      defaultValue: "0",
      displayName: "Button Vertical Offset",
      hidden: props => props.hideButtons || props.tabStyle === "vertical"
    },
    noYPadding: {
      type: "boolean",
      defaultValue: false,
      displayName: "No Vertical Padding",
      description: "Remove vertical padding from the tab panels.",
    },
    // Advanced
    hideButtons: {
      type: "boolean",
      defaultValue: false,
      displayName: "Hide Buttons",
      helpText: "Hide the navigation buttons. Only use in scenarios where all tabs can be seen at once.",
      advanced: true
    },
  },
  actions: [
    {
      type: "button-action",
      label: "Add Tab",
      onClick: ({ studioOps }) => {
        studioOps.appendToSlot(
          {
            type: "component",
            name: "TabItem",
          },
          "children"
        );
      },
    },
  ],
});


PLASMIC.registerComponent(TabItem, {
  name: "TabItem",
  displayName: "Tab Item",
  props: {
    heading: {
      type: "slot",
      defaultValue: [
        {
          type: "text",
          value: "Tab Heading",
          styles: {
            fontSize: "16px",
            fontWeight: "normal",
          },
        },
      ],
    },
    content: {
      type: "slot",
      defaultValue: [
        {
          type: "text",
          value: "Add Tab Content...",
          styles: {
            maxWidth: "100%",
          },
        },
      ],
    },
  },
});

PLASMIC.registerComponent(ControlledSliders, {
  name: "ControlledSliders",
  displayName: "Controlled Sliders",
  props: {
    children: {
      type: "slot",
      allowedComponents: ["Slider"],
      defaultValue: [
        {
          type: "component",
          name: "Slider",
          props: {
            sliderId: "first-slider",
          }
        },
        {
          type: "component",
          name: "Slider",
          props: {
            sliderId: "second-slider",
          }
        },
      ],
    },
    // onFirstSliderInit: {
    //   type: "eventHandler",
    //   argTypes: [{ name: "slider", type: "object" }],
    // },
    // onSecondSliderInit: {
    //   type: "eventHandler",
    //   argTypes: [{ name: "slider", type: "object" }],
    // },
  },
  // states: {
  //   firstSlider: {
  //     type: "readonly",
  //     variableType: "object",
  //     onChangeProp: "onFirstSliderInit",
  //     initVal: {},
  //   },
  //   secondSlider: {
  //     type: "readonly",
  //     variableType: "object",
  //     onChangeProp: "onSecondSliderInit",
  //     initVal: {},
  //   },
  // },
});
                

PLASMIC.registerComponent(Slider, {
  name: "Slider",
  props: {
    children: {
      type: "slot",
      //allowedComponents: ["SliderItem"],
      defaultValue: [
        {
          type: "component",
          name: "SliderItem",
        },
        {
          type: "component",
          name: "SliderItem",
        },
      ],
    },
    previewSlide: {
      type: "custom",
      control: PreviewControl,
      displayName: "Preview Slide",
      defaultValue: 0,
    },
    sliderId: {
      type: "string",
      displayName: "Slider ID",
      description: "Unique ID for the slider.",
    },
    slidesPerView: {
      type: "number",
      control: "slider",
      step: 0.25,
      min: 0,
      max: 12,
      defaultValue: 3,
      displayName: "Slides per View",
      description: "Set to 0 to size slides automatically",
    },
    tabletSlidesPerView: {
      type: "number",
      control: "slider",
      step: 0.25,
      min: 0,
      max: 12,
      defaultValue: 2,
      displayName: "Tablet Slides per View",
      description: "Set to 0 to size slides automatically",
    },
    mobileSlidesPerView: {
      type: "number",
      control: "slider",
      step: 0.25,
      min: 0,
      max: 12,
      defaultValue: 1,
      displayName: "Mobile Slides per View",
      description: "Set to 0 to size slides automatically",
    },
    slidesToScroll: {
      type: "number",
      control: "slider",
      step: 1,
      min: 1,
      max: 9,
      defaultValue: 1,
      displayName: "Slides to Scroll",
    },
    grid: {
      type: "boolean",
      defaultValue: false,
      displayName: "Grid Mode",
    },
    rows: {
      type: "number",
      control: "slider",
      step: 1,
      min: 1,
      max: 12,
      defaultValue: 3,
      displayName: "Rows",
      hidden: props => !props.grid
    },
    loop: {
      type: "boolean",
      defaultValue: true,
      displayName: "Loop Slider",
    },
    touch: {
      type: "boolean",
      defaultValue: true,
      displayName: "Touch Enabled",
      hidden: props => props.ticker
    },
    autoplay: {
      type: "boolean",
      defaultValue: false,
      displayName: "Autoplay",
      description: "Note: If both autoplay and loop are enabled, slides per view must be set.",
    },
    autoplaySpeed: {
      type: "number",
      control: "slider",
      step: 500,
      min: 500,
      max: 20000,
      defaultValue: 10000,
      displayName: "Autoplay Speed",
      helpText: "Speed in milliseconds (larger number = slower speed).",
      hidden: props => !props.autoplay
    },
    autoplayDelay: {
      type: "number",
      control: "slider",
      step: 500,
      min: 500,
      max: 20000,
      defaultValue: 10000,
      displayName: "Autoplay Delay",
      helpText: "Delay between transitions in milliseconds.",
      hidden: props => !props.autoplay || props.ticker
    },
    autoplayPause: {
      type: "boolean",
      defaultValue: false,
      displayName: "Pause on Hover",
      hidden: props => !props.autoplay
    },
    ticker: {
      type: "boolean",
      defaultValue: false,
      displayName: "Ticker Mode",
      description: "Smooth animation of autoplay.",
      hidden: props => !props.autoplay
    },
    slideSpacing: {
      type: "choice",
      options: [
        {
          value: "justify-normal",
          label: "Default"
        },
        {
          value: "justify-start",
          label: "Start"
        },
        {
          value: "justify-end",
          label: "End"
        },
        {
          value: "justify-between",
          label: "Space Between"
        },
        {
          value: "justify-around",
          label: "Space Around"
        },
        {
          value: "justify-center",
          label: "Center"
        }
      ],
      defaultValue: "justify-normal",
      displayName: "Slide Positioning"
    },
    spacing: {
      type: "number",
      control: "slider",
      step: 2,
      min: 0,
      max: 80,
      defaultValue: 20,
      displayName: "Slide Spacing",
    },
    centeredSlides: {
      type: "boolean",
      defaultValue: false,
      displayName: "Center Slides",
    },
    showDots: {
      type: "boolean",
      defaultValue: false,
      displayName: "Show Pagination",
    },
    dotsColor: {
      type: "choice",
      options: [
        {
          value: "default",
          label: "Default"
        },
        {
          value: "light",
          label: "Light"
        },
        {
          value: "dark",
          label: "Dark"
        }
      ],
      defaultValue: "default",
      displayName: "Pagination Color Scheme",
      hidden: props => !props.showDots
    },
    dotsSpacing: {
      type: "string",
      displayName: "Pagination Spacing",
      defaultValue: "30px",
      hidden: props => !props.showDots
    },
    dotsClasses: {
      type: "string",
      displayName: "Pagination Wrapper Classes",
      defaultValue: "",
      description: "Add custom classes to the pagination wrapper.",
      hidden: props => !props.showDots
    },
    arrowDisplay: {
      type: "choice",
      options: [
        {
          value: "default",
          label: "Default"
        },
        {
          value: "show",
          label: "Always Show"
        },
        {
          value: "hide",
          label: "Always Hide"
        },
      ],
      defaultValue: "default",
      displayName: "Arrow Display"
    },
    buttonsWithPagination: {
      type: "boolean",
      defaultValue: false,
      displayName: "Display Arrows with Pagination",
      description: "Arrows show on either side of the pagination. If false, arrows show as buttons on either side of the slider.",
      helpText: "If false, render arrows as buttons at sides of slider.",
      hidden: props => props.arrowDisplay === "hide" || !props.showDots
    },
    animateButtons: {
      type: "boolean",
      defaultValue: false,
      displayName: "Animate Buttons",
      hidden: props => props.arrowDisplay === "hide" || (props.showDots && props.buttonsWithPagination)
    },
    overlayButtons: {
      type: "boolean",
      defaultValue: false,
      displayName: "Overlay Buttons",
      hidden: props => props.arrowDisplay === "hide" || (props.showDots && props.buttonsWithPagination)
    },
    buttonBackgroundColor: {
      type: "custom",
      control: ColorPalette,
      defaultValue: "#FFFFFF",
      displayName: "Button Background Color",
      hidden: props => props.arrowDisplay === "hide" || (props.showDots && props.buttonsWithPagination)
    },
    buttonXOffset: {
      type: "string",
      defaultValue: "1.875rem",
      displayName: "Button Horizontal Offset",
      hidden: props => props.arrowDisplay === "hide" || (props.showDots && props.buttonsWithPagination)
    },
    buttonOffset: {
      type: "string",
      displayName: "Button Vertical Offset",
      hidden: props => props.arrowDisplay === "hide" || (props.showDots && props.buttonsWithPagination)
    },
    wrapperClasses: {
      type: "string",
      defaultValue: "",
      displayName: "Wrapper Classes"
    },
    onActiveSlideChange: {
      type: "eventHandler",
      argTypes: [{ name: "activeSlide", type: "number" }]
    },
  },
  states: {
    activeSlide: {
      type: "readonly",
      variableType: "number",
      onChangeProp: "onActiveSlideChange",
      initVal: 0,
    },
  },
  actions: [
    {
      type: "button-action",
      label: "Add Slide",
      onClick: ({ studioOps }) => {
        studioOps.appendToSlot(
          {
            type: "component",
            name: "SliderItem",
          },
          "children"
        );
      },
    },
  ],
});

PLASMIC.registerComponent(SliderItem, {
  name: "SliderItem",
  displayName: "Slider Item",
  parentComponentName: "Slider",
  props: {
    children: {
      type: "slot",
      defaultValue: [
        {
          type: "text",
          value: "Add Slider Content...",
          styles: {
            maxWidth: "100%",
          },
        },
      ],
    },
  },
});

PLASMIC.registerComponent(PagePropsProvider, {
  name: "PagePropsProvider",
  displayName: "Page Props Provider",
  props: {
    children: "slot",
    //gets page path and sets prop so it's available when previewing
    pageRoute: {
      type: "custom",
      control: PageRoute,
    },
  },
  providesData: true,
});

PLASMIC.registerComponent(RelatedResourcesBlade, {
  name: "RelatedResourcesBlade2",
  displayName: "Related Resources Blade 2",
  props: {
    //gets page path and sets prop so it's available when previewing
    pageRoute: {
      type: "custom",
      control: PageRoute,
    },
  },
});

PLASMIC.registerComponent(ClickEvent, {
  name: "ClickEvent",
  displayName: "GA Event",
  isAttachment: true,
  styleSections: false, // This component does not accept className
  props: {
    children: "slot",
    eventDescription: "string",
  },
});

PLASMIC.registerComponent(MasonryWrapper, {
  name: "MasonryWrapper",
  displayName: "Masonry Wrapper",
  props: {
    children: "slot",
    columns: {
      type: "number",
      control: "slider",
      step: 1,
      min: 1,
      max: 3,
      defaultValue: 1,
    },
  },
});

PLASMIC.registerComponent(Pagination, {
  name: "Pagination",
  displayName: "Pagination",
  props: {
    pageCount: { type: "number", defaultValue: 1 },
    activePage: { type: "number", defaultValue: 1 },
    basePath: { type: "string" },
  },
});

PLASMIC.registerComponent(CountdownPlasmic, {
  name: 'Countdown',
  props: {
    showHeading: {
      type: "boolean",
      defaultValue: true,
      displayName: "Show Heading",
    },
    countdownHeading: {
    type: "string",
    defaultValue: "Event starts in:",
      displayName: "Countdown Heading",
      hidden: props => !props.showHeading
    },
    endDate: {
      type: 'custom',
      control: CustomDateProp,
      displayName: 'End Date',
      defaultValue: '2030-10-01T12:00'
    },
    textColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Text Color",
    },
    size: {
      type:"choice",
      displayName: "Size",
      defaultValue: "standard",
      options: [
        {
          value: "standard",
          label: "Standard"
        },
        {
          value: "large",
          label: "Large"
        }
      ]
    },
    dividerColor: {
      type: "custom",
      control: ColorPalette,
      displayName: "Divider Color",
    },
  }
});
