import { ArrowLeft, ChevronDown } from "@components/Icons";
import Target from "@components/Target";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import clsx from "clsx";
import cookieCutter from 'cookie-cutter';
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";

const CareersList = ({ departmentId }) => {
  function GetDepartments(departmentId) {
    const fetcher = (url) => fetch(url).then((r) => r.json());
    const { data, error } = useSWR(
      "https://boards-api.greenhouse.io/v1/boards/abnormalsecurity/departments",
      fetcher
    );
    if (error) return <div>failed to load</div>;
    if (!data) return <div>loading...</div>;
    if (departmentId) {
      return data.departments.filter(filteredDepartment);
    }
    const departments = data.departments.filter(e => e.id !== 4045805003)
    return departments;
  }

  const careersList = useRef(null);
  const departments = GetDepartments(departmentId);
  const router = useRouter();

  const [ghSrc, setGhSrc] = useState(null);
  useEffect(() => {
    const params = router.query;
    const scrollTo = () =>
      careersList.current.scrollIntoView({ block: "start" });
    if (params.scroll) {
      scrollTo();
      window.scrollBy(0, -200);
    }

    if (router.query['gh_src']) {
      setGhSrc(router.query['gh_src'])
    }
    else if (cookieCutter.get('urlParams') && JSON.parse(cookieCutter.get('urlParams'))['gh_src']) {
      setGhSrc(JSON.parse(cookieCutter.get('urlParams'))['gh_src'])
    }
  }, [router.query]);

  const [selectedCategoryState, setSelectedCategory] = useState(null);

  function filteredDepartment(department) {
    return department.id == departmentId;
  }

  function hasJobsList(department) {
    return department.jobs.length;
  }
  function hasJobs(department) {
    if (selectedCategoryState) {
      return department === selectedCategoryState;
    }
    return department.jobs.length;
  }

  return (
    <div ref={careersList}>
      <div
        className={clsx(
          "CareersList__department max-w-screen-lg mx-auto mb-7.5 px-5",
          "flex-grow"
        )}
      >
        {departments.length && !departmentId && (
          <Listbox value={selectedCategoryState} onChange={setSelectedCategory}>
            <div className="relative">
              <ListboxButton className="relative w-full pl-4 pr-10 text-left border-b cursor-pointer h-14 bg-white-01 border-black-01 ">
                <span className={clsx("block truncate")}>
                  {selectedCategoryState
                    ? selectedCategoryState.name
                    : "Filter by Department"}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                  <ChevronDown  />
                </span>
              </ListboxButton>

              <ListboxOptions
                className={clsx(
                  "absolute z-10 w-full mt-1 overflow-auto text-base shadow bg-white-01 max-h-60 ring-opacity-5 focus:outline-none",
                  "flex flex-col",
                  "py-2"
                )}
              >
                <ListboxOption
                  key={null}
                  value={null}
                  className={clsx("px-4 py-3", "hover:bg-white-02")}
                >
                  All Departments
                </ListboxOption>

                {departments.filter(hasJobsList).map((category) => {
                  return (
                    <ListboxOption
                      key={category.id}
                      value={category}
                      className={clsx("px-4 py-3", "hover:bg-white-02")}
                    >
                      {category.name}
                    </ListboxOption>
                  );
                })}
              </ListboxOptions>
            </div>
          </Listbox>
        )}
      </div>
      {departments.length &&
        departments.filter(hasJobs).map((department) => {
          return (
            <div
              key={department.id}
              className={clsx(
                "CareersList__department max-w-screen-lg mx-auto mb-7.5 px-5",
                "flex-grow"
              )}
            >
              {departmentId && (
				<div
					className={clsx(
					"PageTitle__breadcrumb",
					"text-sm opacity-25",
					"mb-4",
					"max-w-screen-xl mx-auto"
					)}
				>
					<Target
					target={{ url: '/about/careers/?scroll=careersList', type: "custom" }}
					className={clsx(
						"CareerBreadcrumb__Target",
						"flex items-center",
						"md:pl-0 lg:pr-7.5 py-7.5",
						"hover-underline",
						"group",
					)}>
					<div
						className={clsx(
						"EntryThumbnail__targetIcon",
						"mr-4",
						"transition-transform group-hover:translate-x-1"
						)}
					>
						<ArrowLeft />
					</div>
					<span className={clsx("inline-block pb-[2px]")}>View All Openings</span>
					</Target>
				</div>
              )}
              <h2 className={clsx("text-2xl mb-3 font-bold")}>
                {department.name}
              </h2>
              {department.jobs.length &&
                department.jobs.map((job) => {
                  
                  return (
                    <Target
                      key={job.id}
                      target={{
                        url: "/about/careers/" + job.id + "?gh_jid=" + job.id + '&gh_src=' + ghSrc,
                        type: "custom",
                      }}
                      className={clsx(
                        "CareersList__item block max-w-screen-lg mx-auto mb-3",
                        "flex-grow"
                      )}
                    >
                      <h2 className={clsx("text-xl")}>{job.title}</h2>
                      <span className={clsx("text-black-01/75")}>
                        {job.location.name}
                      </span>
                    </Target>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default CareersList;
