import { Form } from "@sharedComponents/Form";
import { FormDataPlasmic } from "@sharedInterfaces/FormData.interface";
import { useEffect, useState } from "react";

/* Reduce children to an array of only the form fields */
const findFields = (items) => {
  // Ensure items array exists
  if (!items || !Array.isArray(items)) return [];
  const fields = [];
  for (const item of items) {
    // If item is undefined or does not have props, skip
    if (!item || !item.props) continue;
    // If item has children, recursively call this function
    if (item.props.children && item.props.children.length > 1) {
      fields.push(...findFields(item.props.children));
    } else {
      // If item has a prop called fieldName, add it to the fields array
      item.props.fieldName && fields.push(item.props);
    }
  }
  return fields;
}


const EmbeddedFormPlasmic = ({
  className,
  children,
  darkBackground,
  formName,
  inputColorScheme,
  marketoFormId,
  showHidden,
  successMessage,
  setControlContextData,
}: FormDataPlasmic) => {

  setControlContextData?.({
    showHidden: showHidden,
  });
    
  /* Form field data */
  const [formFields, setFormFields] = useState([]); /* Form fields for the current form */
  useEffect(() => {
    /* Reduce children to an array of only the form fields */
    const fields = findFields(children);
    setFormFields(fields);
  }, [children]);

  return (
    <Form
      className={className}
      darkBackground={darkBackground}
      formFields={formFields}
      formName={formName}
      inputColorScheme={inputColorScheme}
      isPlasmic={true}
      marketoFormId={marketoFormId}
      showHidden={showHidden}
      successMessage={successMessage}
    >
      {children}
    </Form>
  );
};

export default EmbeddedFormPlasmic;
