import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';

interface IProps {
  showHeading?: boolean;
  countdownHeading?: string;
  endDate?: string;
  textColor?: string;
  dividerColor?: string;
  size: 'standard' | 'large';
}

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const CountdownPlasmic = ({
  showHeading = true,
  countdownHeading = "Event starts in:",
  endDate,
  textColor = "#0d1418",
  dividerColor = "#0d1418",
  size
}: IProps) => {
  const [selectedDate, setSelectedDate] = useState<string>(endDate || '');
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({ days: 0, hours: 0, minutes: 0, seconds: 0, });

  const IndicatorBox: React.FC<{ value: number; label: string; size: string }> = ({ value, label, size }) => (
    <Box style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '0 31px 0 0'}}>
      { size === "standard" && (
        <>
          <span style={{fontSize: "52px", fontStyle: "normal", fontWeight: "400", lineHeight: "60px"}}>{value}</span>
          <span style={{fontFamily: "TWK Everett", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>{label}</span>
        </>
      )}
      { size === "large" && (
        <>
          <span style={{fontSize: "83px", fontStyle: "normal", fontWeight: "400", lineHeight: "96px"}}>{value}</span>
          <span style={{fontFamily: "TWK Everett", fontSize: "26px", fontStyle: "normal", fontWeight: "400", lineHeight: "38px"}}>{label}</span>
        </>
      )}
    </Box>
  );

  const divider = (
    <svg width="1" height="39" viewBox="0 0 1 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="0" x2="0.5" y2="39" stroke={dividerColor} />
    </svg>
  );

  const calculateTimeLeft = () => {
    if (selectedDate) {
      const difference = +new Date(selectedDate) - +new Date();
      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  useEffect(() => {
    setSelectedDate(endDate || '');
  }, [endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedDate]);

  return (
    <Flex align="center" color={textColor}>
      {showHeading && (
        <p style={{fontFamily: "TWK Everett", fontSize: "24px", fontStyle: "normal", fontWeight: "400", lineHeight: "36px",}}>
          {countdownHeading}
        </p>
      )}
      <Box style={{display: "grid", gridTemplateColumns: "1fr auto 1fr auto 1fr", alignItems: "center"}}>
        <IndicatorBox size={size} value={timeLeft.days} label="Days" />
        {divider}
        <IndicatorBox size={size} value={timeLeft.hours} label="Hours" />
        {divider}
        <IndicatorBox size={size} value={timeLeft.minutes} label="Minutes" />
      </Box>
    </Flex>
  );
};

export default CountdownPlasmic;
